import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import {
	clearNotifications,
	createNotification,
	fetchNotifications,
	readNotifications,
} from './actions'
import { Notifications } from './types'
import { set } from 'utils/localStore'

export const NOTIFICATION_INITIAL_STATE: Notifications[] = [
	{
		id: '',
		type: '',
		message: '',
		userId: '',
		details: '',
		key: undefined,
		asset: undefined,
		positionType: undefined,
		size: '',
		price: '',
	},
]

interface NotificationState {
	notifications: Notifications[]
	status: 'idle' | 'loading' | 'succeeded' | 'failed'
	error: string | null
}

const initialState: NotificationState = {
	notifications: [],
	status: 'idle',
	error: null,
}

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		clearStateOnDisconnect: (state) => {
			state.notifications = []
			state.status = 'idle'
			state.error = null
			set('unreadNotifications', false)
		},
	},
	extraReducers: (builder) => {
		// Handle fetchNotifications
		builder
			.addCase(fetchNotifications.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<Notifications[]>) => {
				state.status = 'succeeded'
				state.notifications = action.payload
				set('unreadNotifications', false)
			})
			.addCase(fetchNotifications.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message || 'Failed to fetch notifications'
			})
		// Handle createNotification
		builder
			.addCase(createNotification.fulfilled, (state, action: PayloadAction<Notifications>) => {
				state.notifications = [action.payload]
				set('unreadNotifications', true)
			})
			.addCase(createNotification.rejected, (state, action) => {
				state.error = action.error.message || 'Failed to create notification'
			})
		// Handle readNotifications (specific notification or all notifications)
		builder
			.addCase(readNotifications.fulfilled, (state, action: PayloadAction<Notifications[]>) => {
				state.notifications = action.payload
				set('unreadNotifications', false)
			})
			.addCase(readNotifications.rejected, (state, action) => {
				state.error = action.error.message || 'Failed to read notifications'
			})
		// Handle clearNotifications (specific notification or all notifications)
		builder
			.addCase(clearNotifications.fulfilled, (state, action: PayloadAction<Notifications[]>) => {
				state.notifications = action.payload
				set('unreadNotifications', false)
			})
			.addCase(clearNotifications.rejected, (state, action) => {
				state.error = action.error.message || 'Failed to clear notifications'
			})
	},
})

export const { actions } = notificationsSlice
export const { clearStateOnDisconnect } = notificationsSlice.actions

export default notificationsSlice.reducer
