import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { truncateString } from '@dextoroprotocol/sdk/utils'
import {
	Container,
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
	Paragraph,
} from 'constants/NotificationContainer'
import ICON from 'assets/svg/app/warning-icon.svg'
import styled, { css } from 'styled-components'

export function RestrcitedLocationWarningToastContent({
	message,
	details,
	linkText,
}: {
	message: string
	details: string
	linkText: string
}) {
	return (
		<RestrictedContainer>
			<NotificationContent>
				<NotificationTitle>
					<NotificationIconDiv>
						<ICON />
					</NotificationIconDiv>
					{message}
				</NotificationTitle>
				<NotificationDetails text={details} linkText={linkText} path={'/terms'} />
			</NotificationContent>
		</RestrictedContainer>
	)
}

const RestrictedContainer = styled(Container)`
	background: ${(props) => props.theme.colors.selectedTheme.compliance.background};
	border-radius: 8px;
	${NotificationIconDiv} {
		svg {
			height: 24px !important;
			width: 24px !important;
			fill: none !important;
		}
	}
	${NotificationTitle} {
		font-size: 20px;
		color: ${(props) => props.theme.colors.selectedTheme.modal.text.primaryWhite};
	}
	${Paragraph} {
		font-size: 16px;
		font-weight: 400;
		color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
		cursor: default;
		a {
			color: ${(props) => props.theme.colors.selectedTheme.modal.text.primaryWhite};
			font-weight: 400;
			cursor: pointer;
			&:hover {
				color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
				text-decoration: none;
			}
		}
	}
	${NotificationContent} {
		gap: 12px;
	}
`

export const RestrictedLocationsWarning = (message: string, details: string, linkText: string) => {
	toast.info(
		<RestrcitedLocationWarningToastContent
			message={message}
			details={details}
			linkText={linkText}
		/>,
		{
			position: toast.POSITION.TOP_RIGHT,
			toastId: message,
			containerId: 'notifications',
			autoClose: false,
		}
	)
}
