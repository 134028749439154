import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { FuturesMarketKey, FuturesOrderTypeDisplay } from '@dextoroprotocol/sdk/types'

import { FlexDivCentered } from 'components/layout/flex'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { MessageTitle } from 'sections/shared/Layout/AppLayout/Header/Notifications'
import { Container, NotificationContent } from 'constants/NotificationContainer'

type tnsDetailsProps = {
	key: FuturesMarketKey | undefined
	orderType: FuturesOrderTypeDisplay | undefined
	blockExplorerLink?: string
}

function ToastContent({ message, tnsDetails }: { message: string; tnsDetails?: tnsDetailsProps }) {
	return (
		<Container
			onClick={() =>
				tnsDetails?.blockExplorerLink && window.open(tnsDetails?.blockExplorerLink, '_blank')
			}
		>
			<NotificationContent>
				<MessageTitle style={{ textTransform: 'capitalize' }}>
					<FlexDivCentered>
						<StyledCurrencyIcon currencyKey={tnsDetails?.key ?? ''} width={25} height={25} />
						{message}
					</FlexDivCentered>
					<OrderTypeBox>
						Canceled <Icon_CANCEL />
					</OrderTypeBox>
				</MessageTitle>
			</NotificationContent>
		</Container>
	)
}

export const notifyCancelOrder = (message: string, tnsDetails?: tnsDetailsProps) => {
	toast.info(<ToastContent message={message} tnsDetails={tnsDetails} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'notifications',
	})
}

const StyledCurrencyIcon = styled(CurrencyIcon)`
	margin-left: -2px;
	margin-right: 4px;
`

const OrderTypeBox = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

export const Icon_CANCEL = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		color="#ff5353"
		style={{
			width: '0.9375rem',
			height: '0.9375rem',
			minWidth: '1rem',
			minHeight: '1rem',
			marginLeft: 6,
		}}
	>
		<circle cx="8" cy="8" r="7.5" stroke="currentColor"></circle>
		<path
			d="M5.14285 5.14282L10.8571 10.8571"
			stroke="currentColor"
			stroke-width="1.25"
			stroke-linecap="round"
		></path>
		<path
			d="M10.8571 5.14282L5.14286 10.8571"
			stroke="currentColor"
			stroke-width="1.25"
			stroke-linecap="round"
		></path>
	</svg>
)
