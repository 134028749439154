import React, { useEffect } from 'react'
import styled from 'styled-components'
import { PositionSide } from '@dextoroprotocol/sdk/types'
import { getDisplayAsset } from '@dextoroprotocol/sdk/utils'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectSmartMarginAccount } from 'state/futures/smartMargin/selectors'
import { showNotification } from 'state/app/reducer'
import {
	clearNotifications,
	fetchNotifications,
	readNotifications,
} from 'state/notifications/actions'
import { clearStateOnDisconnect } from 'state/notifications/reducer'
import {
	selectAllNotifications,
	selectNotificationError,
	selectNotificationStatus,
} from 'state/notifications/selectors'
import useClickOutside from 'hooks/useClickOutside'
import Connector from 'containers/Connector'

import PositionType from 'sections/futures/PositionType'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import { FlexDivCentered, FlexDivRowCentered } from 'components/layout/flex'
import Input from 'components/Input/Input'
import Button from 'components/Button'
import Loader from 'components/Loader'

import { zIndex } from 'constants/ui'
import media from 'styles/media'
import { MobilePageTitle } from 'styles/common'
import {
	NotificationContent,
	NotificationDetails,
	NotificationIconDiv,
	NotificationTitle,
} from 'constants/NotificationContainer'
import { Icon_CANCEL } from 'components/Notifications/CancelOrder'
import { Icon_CREATED } from 'components/Notifications/ConditionalOrder'
import WarningIcon from 'assets/svg/app/warning-icon.svg'
import SuccessIcon from 'assets/svg/app/success-new.svg'
import CloseIcon from 'assets/svg/app/close.svg'

const Notifications: React.FC = () => {
	const dispatch = useAppDispatch()
	const { isWalletConnected } = Connector.useContainer()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)
	const notifications = useAppSelector(selectAllNotifications)
	const status = useAppSelector(selectNotificationStatus)
	const error = useAppSelector(selectNotificationError)
	const [showComplianceWarning, setShowComplianceWarning] = React.useState<boolean>(false)
	// Check sessionStorage for compliance notification status
	const complianceNotificationShow = sessionStorage.getItem('complianceNotificationShow')
	const [searchQuery, setSearchQuery] = React.useState<string>('')

	const { ref } = useClickOutside(() => dispatch(showNotification(false)))
	const handleMarkedAsRead = () => {
		dispatch(readNotifications({ userId: smartMarginAccount ?? '' }))
		dispatch(fetchNotifications(smartMarginAccount ?? ''))
	}
	const handleClearAll = (userId: string) => {
		dispatch(clearNotifications({ userId }))
		sessionStorage.removeItem('complianceNotificationShow')
		setShowComplianceWarning(false)
	}

	useEffect(() => {
		if (complianceNotificationShow) {
			setShowComplianceWarning(true)
		} else {
			setShowComplianceWarning(false)
		}
	}, [complianceNotificationShow])

	useEffect(() => {
		if (isWalletConnected && smartMarginAccount) {
			dispatch(fetchNotifications(smartMarginAccount ?? ''))
		} else {
			dispatch(clearStateOnDisconnect())
		}
	}, [dispatch, isWalletConnected, smartMarginAccount])

	// Filter notifications based on the search query
	const filterNotifications = (notifications: any[], query: string) => {
		if (!query) return notifications

		return notifications.filter((notification) => {
			const lowerQuery = query.toLowerCase()
			return (
				notification.message.toLowerCase().includes(lowerQuery) ||
				notification.type.toLowerCase().includes(lowerQuery) ||
				(notification.details && notification.details.toLowerCase().includes(lowerQuery)) ||
				(notification.marketKey && notification.marketKey.toLowerCase().includes(lowerQuery)) ||
				(notification.status && notification.status.toLowerCase().includes(lowerQuery))
			)
		})
	}

	// @ts-ignore
	const notificationData = notifications?.data
	const filteredNotifications = filterNotifications(notificationData, searchQuery)

	const isFeedEmpty =
		filteredNotifications?.length === 0 ||
		filteredNotifications == undefined ||
		filteredNotifications == null

	const allRead = filteredNotifications?.some((item: { read: boolean }) => item?.read === false)

	return (
		<>
			<DesktopOnlyView>
				<Container ref={ref}>
					<FullHeightContainer>
						<Header>
							<FlexDivRowCentered style={{ minWidth: '100%' }}>
								Notifications
								<CancelIconDiv onClick={() => dispatch(showNotification(false))}>
									<CloseIcon />
								</CancelIconDiv>
							</FlexDivRowCentered>
							<SearchBar
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								placeholder="Search"
								autoFocus
							/>
						</Header>
						<NotifyContainer>
							{status === 'loading' ? (
								<Loader style={{ top: '20%' }} />
							) : isFeedEmpty && !showComplianceWarning ? (
								<ContentContainer>You have no notifications.</ContentContainer>
							) : (
								<>
									{showComplianceWarning && (
										<MessageContainer>
											<NotificationContent key="compliance-warning">
												<NotificationTitle>
													<WarningNotificationIconDiv>
														<WarningIcon />
													</WarningNotificationIconDiv>
													Compliance Warning
												</NotificationTitle>
												<NotificationDetails
													text="Perpetuals are not available to any persons who are residents of, are located or incorporated in, or have a registered agent in a blocked country or a restricted territory. More details can be found in our"
													linkText="Terms of Use."
													path="/terms"
												/>
											</NotificationContent>
											<CloseIconDiv
												onClick={(e) => {
													e.stopPropagation()
													sessionStorage.removeItem('complianceNotificationShow')
													setShowComplianceWarning(false)
												}}
											>
												<CloseIcon />
											</CloseIconDiv>
										</MessageContainer>
									)}
									{filteredNotifications?.map((n: NotificationItem) => (
										<RenderNotification item={n} />
									))}
								</>
							)}
						</NotifyContainer>
						<Footer>
							<ReadButton onClick={handleMarkedAsRead} disabled={!allRead || isFeedEmpty}>
								Mark as read
							</ReadButton>
							<ClearButton
								onClick={() => handleClearAll(smartMarginAccount ?? '')}
								disabled={isFeedEmpty && !showComplianceWarning}
							>
								Clear All
							</ClearButton>
						</Footer>
					</FullHeightContainer>
				</Container>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<MobileContainer>
					<Header>
						<Footer style={{ padding: 0 }}>
							<ReadButton onClick={handleMarkedAsRead} disabled={!allRead || isFeedEmpty}>
								Mark as read
							</ReadButton>
							<ClearButton
								onClick={() => handleClearAll(smartMarginAccount ?? '')}
								disabled={isFeedEmpty && !showComplianceWarning}
							>
								Clear All
							</ClearButton>
						</Footer>
						<SearchBar
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							placeholder="Search"
							autoFocus
						/>
					</Header>
					{status === 'loading' ? (
						<Loader style={{ top: '50%' }} />
					) : isFeedEmpty && !showComplianceWarning ? (
						<MobileNotifyContainer>
							<MobilePageTitle>You have no notifications.</MobilePageTitle>
						</MobileNotifyContainer>
					) : (
						<>
							{showComplianceWarning && (
								<MessageContainer>
									<NotificationContent key="compliance-warning">
										<NotificationTitle>
											<WarningNotificationIconDiv>
												<WarningIcon />
											</WarningNotificationIconDiv>
											Compliance Warning
										</NotificationTitle>
										<NotificationDetails
											text="Perpetuals are not available to any persons who are residents of, are located or incorporated in, or have a registered agent in a blocked country or a restricted territory. More details can be found in our"
											linkText="Terms of Use."
											path="/terms"
										/>
									</NotificationContent>
									<CloseIconDiv
										onClick={(e) => {
											e.stopPropagation()
											sessionStorage.removeItem('complianceNotificationShow')
											setShowComplianceWarning(false)
										}}
									>
										<CloseIcon />
									</CloseIconDiv>
								</MessageContainer>
							)}
							{filteredNotifications?.map((n: NotificationItem) => (
								<RenderNotification item={n} />
							))}
						</>
					)}
				</MobileContainer>
			</MobileOrTabletView>
		</>
	)
}

const WarningNotificationIconDiv = styled(NotificationIconDiv)`
	height: auto;
	svg {
		height: 23px;
		width: 20px;
		fill: none;
	}
`
export default Notifications

// Notification Types
interface BaseItem {
	_id: string
	type: string
	message: string
	details?: string
	userId: string
	blockExplorerLink?: string
	read?: boolean
}

interface MarketOrderItem extends BaseItem {
	type: 'market' | 'limit' | 'stop_market'
	status?: 'Created' | 'Open' | 'Filled' | 'Canceled'
	marketKey: string
	marketAsset: string
	positionType: string
	size: string
	price: string
}

interface TransfersItem extends BaseItem {
	type: 'deposit success' | 'withdraw success'
}

interface CancelOrderItem extends BaseItem {
	type: 'Cancel Order'
	marketKey: string
}

type NotificationItem = MarketOrderItem | TransfersItem | CancelOrderItem

// Render the correct component based on the type of notification
const RenderNotification: React.FC<{ item: NotificationItem; newContent?: any }> = ({
	item,
	newContent,
}) => {
	const dispatch = useAppDispatch()
	const smartMarginAccount = useAppSelector(selectSmartMarginAccount)

	const handleClick = async (userId: string, id?: string) => {
		await dispatch(readNotifications({ userId, id }))
		await dispatch(fetchNotifications(smartMarginAccount ?? ''))
		if (item?.blockExplorerLink) {
			window.open(item?.blockExplorerLink, '_blank')
		}
	}

	const handleClear = async (userId: string, id?: string) => {
		await dispatch(clearNotifications({ userId, id }))
		await dispatch(fetchNotifications(smartMarginAccount ?? ''))
	}

	let content
	if (item.type === 'market' || item.type === 'limit' || item.type === 'stop_market') {
		content = <MarketOrder key={item._id} data={item} />
	} else if (item.type === 'deposit success' || item.type === 'withdraw success') {
		content = <Transfers key={item._id} data={item} />
	} else if (item.type === 'Cancel Order') {
		content = <CancelOrder key={item._id} data={item} />
	} else {
		return null
	}

	return (
		<MessageContainer
			read={item?.read}
			onClick={(e) => {
				e.stopPropagation()
				handleClick(smartMarginAccount ?? '', item?._id)
			}}
		>
			{newContent ? newContent : null}
			{content}
			<CloseIconDiv
				onClick={(e) => {
					e.stopPropagation()
					handleClear(smartMarginAccount ?? '', item?._id)
				}}
			>
				<CloseIcon />
			</CloseIconDiv>
		</MessageContainer>
	)
}

const Transfers: React.FC<{ data: TransfersItem }> = ({ data }) => (
	<NotificationContent key={data._id}>
		<NotificationTitle>
			<NotificationIconDiv type="success">
				<SuccessIcon />
			</NotificationIconDiv>
			{data?.message}
		</NotificationTitle>
		{data?.details && <NotificationDetails text={data?.details} />}
	</NotificationContent>
)

const MarketOrder: React.FC<{ data: MarketOrderItem }> = ({ data }) => (
	<NotificationContent key={data?._id} style={{ gap: 4 }}>
		<MessageTitle style={{ textTransform: 'capitalize' }}>
			<FlexDivCentered>
				<StyledCurrencyIcon currencyKey={data?.marketKey} width={25} height={25} />
				{data?.message}
			</FlexDivCentered>
			{data.status && (
				<OrderTypeBox>
					{data.status}
					{data.type === 'market' ? (
						data.status === 'Filled' && data?.positionType === PositionSide.SHORT ? (
							<Icon_SHORT />
						) : (
							<Icon_LONG />
						)
					) : (
						data.status === 'Created' && <Icon_CREATED />
					)}
				</OrderTypeBox>
			)}
		</MessageTitle>
		{data.size && (
			<PositionDetail>
				<Key>
					Size
					<PositionType
						side={data?.positionType === PositionSide.LONG ? PositionSide.LONG : PositionSide.SHORT}
					/>
				</Key>
				<Value>
					{data?.size}
					<Ticker>{getDisplayAsset(data?.marketAsset)}</Ticker>
				</Value>
			</PositionDetail>
		)}
		{data.price && (
			<PositionDetail>
				<Key>Price</Key>
				<Value>{data?.price}</Value>
			</PositionDetail>
		)}
	</NotificationContent>
)

const CancelOrder: React.FC<{ data: CancelOrderItem }> = ({ data }) => (
	<NotificationContent>
		<MessageTitle style={{ textTransform: 'capitalize' }}>
			<FlexDivCentered>
				<StyledCurrencyIcon currencyKey={data?.marketKey} width={25} height={25} />
				{data?.message}
			</FlexDivCentered>
			<OrderTypeBox>
				Canceled <Icon_CANCEL />
			</OrderTypeBox>
		</MessageTitle>
	</NotificationContent>
)

const Container = styled.div`
	top: 0;
	right: 0;
	height: 100%;
	z-index: 9999;
	min-width: 300px;
	max-width: 300px;
	position: absolute;
	filter: brightness(1.2);
	border-left: ${(props) => props.theme.colors.selectedTheme.border};
	background: ${(props) => props.theme.colors.selectedTheme.background};
`

const FullHeightContainer = styled.div`
	height: 100%;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	padding: 1rem;
	font-size: 20px;
	line-height: 24px;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.bold};
	color: ${(props) => props.theme.colors.selectedTheme.primary};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
`

const SearchBar = styled(Input)`
	border: none;
	padding: 8px;
	height: 2.5rem;
	font-size: 16px;
	margin-top: 1rem;
	margin-bottom: 0.25rem;
	border-radius: 0.5rem;
	color: ${(props) => props.theme.colors.selectedTheme.primary};
	::placeholder {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const Footer = styled.footer`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.colors.selectedTheme.background};
	position: sticky;
	padding: 1rem;
	z-index: 9999;
	width: 100%;
	bottom: 0;
	gap: 1rem;
`

const CancelIconDiv = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: content-box;
	border-radius: 0.25rem;
	height: 0.7813rem;
	width: 0.7813rem;
	margin: auto 0px;
	padding: 0.5rem;
	cursor: pointer;
	appearance: none;
	background: none;
	border: none;
	transition: 0.25s cubic-bezier(0.16, 1, 0.3, 1);
	&:hover {
		svg {
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.primary};
			}
		}
	}
	svg {
		height: 100%;
		width: 100%;
		min-width: 1rem;
		min-height: 1rem;
	}
`

const NotifyContainer = styled.div`
	overflow: scroll;
	padding-bottom: 68px;
	height: calc(100% - 64px);
`

const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 28px 12px;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`

const ReadButton = styled(Button)<{ disabled?: boolean }>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5em;
	padding: auto 2.5rem;
	text-align: center;
	background: none;
	height: 2.25rem;
	width: 8rem;
	gap: 0.5ch;
	flex: 1 1 auto;
	font-size: 14px;
	white-space: nowrap;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
	&:disabled {
		background: none;
		color: ${(props) => props.theme.colors.selectedTheme.gray};
		border: ${(props) => props.theme.colors.selectedTheme.border};
	}
`

const ClearButton = styled(Button)<{ disabled?: boolean }>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5em;
	padding: auto 0.5rem;
	text-align: center;
	background: none;
	height: 2.25rem;
	width: 2.5rem;
	gap: 0.5ch;
	flex: 1 1 auto;
	font-size: 14px;
	white-space: nowrap;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	&:hover {
		background: none;
		color: ${(props) => props.theme.colors.selectedTheme.red};
		border: 1px solid ${(props) => props.theme.colors.selectedTheme.red};
	}
	&:disabled {
		color: ${(props) => props.theme.colors.selectedTheme.gray};
		border: ${(props) => props.theme.colors.selectedTheme.border};
	}
`

const MobileContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 68px);
	overflow-y: auto;
	z-index: ${zIndex.DIALOG_OVERLAY};
	background: ${(props) => props.theme.colors.selectedTheme.background};
`

const MobileNotifyContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 28px 12px;
	line-height: 22px;
	font-size: 18px;
	height: 100%;
`

export const MessageContainer = styled.div<{ read?: boolean }>`
	gap: 8px;
	display: grid;
	cursor: pointer;
	position: relative;
	padding: 16px;
	overflow-y: scroll;
	background: ${(props) => (!props.read ? props.theme.colors.selectedTheme.table.hover : 'none')};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	${media.lessThan('mdUp')`
	  padding: 20px 24px;
  	`}
	&:hover {
		backdrop-filter: brightness(1.1);
	}
`

const StyledCurrencyIcon = styled(CurrencyIcon)`
	margin-right: 4px;
	margin-left: -2px;
`

export const MessageTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`

const OrderTypeBox = styled.div`
	${MessageContainer}:hover & {
		display: none;
	}
	display: flex;
	align-items: center;
	line-height: 20px;
	font-size: 15px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

export const PositionDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 13px;
	line-height: 16px;
`

const Key = styled.span`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	gap: 6px;
`

const Value = styled.span`
	display: flex;
	align-items: center;
	text-transform: capitalize;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	gap: 6px;
`

export const Ticker = styled.div`
	display: inline-flex;
	padding: 0 3px 0 4px;
	border-radius: 2px;
	margin-left: 6px;
	margin: -2px auto;
	font-size: 12px;
	line-height: 16px;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	transition: all 0.15s ease-in-out !important;
	text-transform: uppercase;
	letter-spacing: 0.06em;
`

const CloseIconDiv = styled.div`
	${MessageContainer}:hover & {
		display: flex;
	}
	display: none;
	position: absolute;
	cursor: pointer;
	padding: 4px;
	right: 16px;
	top: 16px;

	svg {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
		}
		${media.lessThan('mdUp')`
			width: 16px;
			height: 16px;
		`}
	}
`

export const Icon_LONG = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ marginLeft: 6 }}
	>
		<circle cx="8" cy="8" r="7.5" stroke="#57ffc3"></circle>
		<circle cx="8" cy="8" r="5" fill="#57ffc3"></circle>
	</svg>
)

export const Icon_SHORT = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ marginLeft: 6 }}
	>
		<circle cx="8" cy="8" r="7.5" stroke="#ff5353"></circle>
		<circle cx="8" cy="8" r="5" fill="#ff5353"></circle>
	</svg>
)
