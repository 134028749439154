import { useMemo, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'

import BaseModal from 'components/BaseModal'
import Button from 'components/Button'
import { Body } from 'components/Text'
import Connector from 'containers/Connector'
import localStore from 'utils/localStore'
import logError from 'utils/logError'
import Logo from 'assets/svg/brand/dextoro-logo.svg'
import LogoLight from 'assets/svg/brand/dextoro-logo-light.svg'
import { useWalkthroughContext } from 'contexts/WalkthroughContext'
import { BASE_URL } from './Walkthrough'
import { saveUserData } from 'state/userData/actions'

export default function AcknowledgementModal() {
	const { walletAddress } = Connector.useContainer()
	const [acks, setAcks] = useState<Record<string, boolean>>({})
	const { setNewTrader } = useWalkthroughContext()
	const currentTheme = useAppSelector(selectCurrentTheme)
	const dispatch = useAppDispatch()

	const DextoroLogo = useMemo(
		() => (currentTheme === 'light' ? <DextoroLightLogo /> : <DextoroDarkLogo />),
		[currentTheme]
	)

	const acknowledgedAddresses = (localStore.get('acknowledgedAddresses') || {}) as Record<
		string,
		boolean
	>

	// const protectedRoute =
	// 	router.asPath.startsWith(ROUTES.Earn.Home) ||
	// 	router.asPath.startsWith(ROUTES.Exchange.Home) ||
	// 	router.asPath.includes('/market')

	if (
		!walletAddress ||
		acks[walletAddress.toLowerCase()] ||
		acknowledgedAddresses[walletAddress.toLowerCase()]
	) {
		return null
	}
	const IPAdress = async () => {
		const res = await axios.get('https://api.ipify.org/?format=json')
		return res.data.ip
	}

	const onAccept = async () => {
		try {
			try {
				const IP = await IPAdress()
				const browserAgent = navigator.userAgent
				const newUserData = {
					walletAddress: walletAddress.toLowerCase(),
					ipAddress: IP,
					browserAgent,
				}
				dispatch(saveUserData(newUserData))
			} catch (error) {
				logError(error)
			}

			acknowledgedAddresses[walletAddress.toLowerCase()] = true
			localStore.set('acknowledgedAddresses', acknowledgedAddresses)
			await axios
				.post(`${BASE_URL}?walletAddress=${walletAddress.toLowerCase()}`)
				.then((res) => setNewTrader(res.data?.walletAddress))
			setAcks({ ...acks, [walletAddress.toLowerCase()]: true })
		} catch (err) {
			logError(err)
		}
	}

	return (
		<StyledBaseModal
			onDismiss={() => {}}
			title={<LogoContainer>{DextoroLogo}</LogoContainer>}
			showCross={false}
		>
			<div>
				<StyledBody>Terms of Use</StyledBody>
			</div>
			<ScrollableContent>
				<BodyTextWrap>
					<BodyText>
						Welcome to DexToro, a decentralized derivatives exchange that aims to promote financial
						equality through accessible investment tools managed by an international community.
					</BodyText>
					<BodyText>
						Please note that DexToro may not be suitable for use in all legal jurisdictions. By
						using our platform, you confirm that you have thoroughly investigated your legal
						situation and sought guidance from a legal representative in your jurisdiction, if
						necessary.{' '}
					</BodyText>

					<BodyText>
						Please ensure that you understand your local regulations well enough to determine
						whether you are authorized to use DexToro, as our reliance on smart contracts and
						blockchain technology means that we cannot block anyone from accessing the protocol. All
						users are responsible for their own actions.{' '}
					</BodyText>
					<BodyText>
						These terms of use are entered into by and between you (as defined below) and DexToro
						Trading Inc. (<b>“Company”</b>, <b>“we”</b> or <b>“us”</b>). The following terms and
						conditions, together with any documents they expressly incorporate by reference
						(collectively, these <b>“Terms of Use”</b>), govern your access to and use of{' '}
						<a href="https://dextoro.com" target="_blank">
							dextoro.com
						</a>{' '}
						and any subdomains thereof (the <b>“Services”</b>) and use of our mobile applications
						(the <b>“Apps”</b> and together with the Services, the <b>“Services”</b>).
					</BodyText>
					<BodyText>
						If the user engages with the Services under authority from a different party or on
						another party’s behalf, then <b>“you”</b> (and its variants, including <b>“your”</b>,
						<b>“yours”</b>, etc.) as used herein refers to that person on whose behalf the Services
						are used (e.g., an employer). If the person engaging with our Services is an individual,
						acting in their own individual capacity, then <b>“you”</b> (and its variants) refers to
						that individual. If you have anyone using the Services on your behalf, you agree that
						you are responsible for the actions and inactions of all such persons, as they were your
						own.
					</BodyText>
					<BodyText>
						The Services provide access to third party content, protocols and software, including
						smart contracts, which may enable you to engage in digital asset trading activities. USE
						OF THE PROTOCOL OR ANY DEXTORO SMART CONTRACT ARE AT YOUR OWN RISK. THE SERVICES ARE A
						NONEXCLUSIVE MEANS TO FACILITATE ACCESS TO THE RELEVANT PROTOCOL AND/OR SMART CONTRACTS.
						WE DO NOT OWN, CONTROL, OR HAVE ANY RESPONSIBILITY FOR THE DEXTORO PROTOCOL OR ANY
						DEXTORO SMART CONTRACT. THESE TERMS OF USE DO NOT GOVERN YOUR USE OF THE PROTOCOL OR ANY
						DEXTORO SMART CONTRACT, WHICH ARE CONTROLLED, MAINTAINED AND/OR OPERATED BY THIRD
						PARTIES. PLEASE CONSULT SUCH THIRD PARTIES’ USER AGREEMENTS FOR INFORMATION REGARDING
						YOUR RIGHTS AND RISKS ASSOCIATED WITH YOUR USE OF AND ACCESS TO THESE MATERIALS.
					</BodyText>
					<BodyText>
						Please read these Terms of Use carefully before you start to use the Services.
						<b>
							By using the Services, you accept and agree to be bound and abide by these Terms of
							Use. In addition, by clicking “I agree” (or a similar language) to these Terms of Use,
							acknowledging these Terms of Use by other means, or otherwise accessing or using the
							Services, you also accept and agree to be bound by and to comply with these Terms of
							Use.
						</b>{' '}
						If you do not want to agree to these Terms of Use, you must not access or use the
						Services.
					</BodyText>
					<BodyText>
						THE SERVICES WERE NOT DEVELOPED FOR, AND ARE NOT AVAILABLE TO PERSONS OR ENTITIES WHO
						RESIDE IN, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED OFFICE OR PRINCIPAL
						PLACE OF BUSINESS IN THE UNITED STATES OF AMERICA, THE UNITED KINGDOM OR CANADA
						(COLLECTIVELY, “BLOCKED PERSONS”). MOREOVER, THE SERVICES ARE NOT OFFERED TO PERSONS OR
						ENTITIES WHO RESIDE IN, ARE CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A
						REGISTERED OFFICE OR PRINCIPAL PLACE OF BUSINESS IN ANY RESTRICTED JURISDICTION OR
						COUNTRY SUBJECT TO ANY SANCTIONS OR RESTRICTIONS PURSUANT TO ANY APPLICABLE LAW,
						INCLUDING THE CRIMEA REGION, CUBA, IRAN, NORTH KOREA, SYRIA, MYANMAR (BURMA, DONETSK,
						LUHANSK, OR ANY OTHER COUNTRY TO WHICH THE UNITED STATES, THE UNITED KINGDOM, THE
						EUROPEAN UNION OR ANY OTHER JURISDICTIONS EMBARGOES GOODS OR IMPOSES SIMILAR SANCTIONS,
						INCLUDING THOSE LISTED ON OUR SERVICES (COLLECTIVELY, THE “RESTRICTED JURISDICTIONS” AND
						EACH A “RESTRICTED JURISDICTION”) OR ANY PERSON OWNED, CONTROLLED, LOCATED IN OR
						ORGANIZED UNDER THE LAWS OF ANY JURISDICTION UNDER EMBARGO OR CONNECTED OR AFFILIATED
						WITH ANY SUCH PERSON (COLLECTIVELY, “RESTRICTED PERSONS”). THE WEBSITE WAS NOT
						SPECIFICALLY DEVELOPED FOR, AND IS NOT AIMED AT OR BEING ACTIVELY MARKETED TO, PERSONS
						OR ENTITIES WHO RESIDE IN, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED
						OFFICE OR PRINCIPAL PLACE OF BUSINESS IN THE EUROPEAN UNION. THERE ARE NO EXCEPTIONS. IF
						YOU ARE A BLOCKED PERSON OR A RESTRICTED PERSON, THEN DO NOT USE OR ATTEMPT TO USE THE
						SERVICES. USE OF ANY TECHNOLOGY OR MECHANISM, SUCH AS A VIRTUAL PRIVATE NETWORK (“VPN”)
						TO CIRCUMVENT THE RESTRICTIONS SET FORTH HEREIN IS PROHIBITED.
					</BodyText>
					<BodyText>
						BY USING THE SERVICES, YOU REPRESENT THAT (1) YOU ARE NOT A BLOCKED PERSON OR RESTRICTED
						PERSON; AND (2) YOU (INCLUDING, IF APPLICABLE, YOUR INDIVIDUAL OWNERS, REPRESENTATIVES,
						EMPLOYEES, OR ANY OTHER PERSON WITH ACCESS TO YOUR SERVICES ACCOUNT) WILL NOT
						COORDINATE, CONDUCT OR CONTROL (INCLUDING BY, IN SUBSTANCE OR EFFECT, MAKING DECISIONS
						WITH RESPECT TO) YOUR USE OF THE SERVICES FROM WITHIN THE UNITED STATES OF AMERICA, THE
						UNITED KINGDOM, CANADA OR ANY RESTRICTED JURISDICTION.
					</BodyText>
					<BodyText>
						YOU ACKNOWLEDGE, UNDERSTAND AND AGREE THAT (1) YOU ARE NOT PERMITTED TO MODIFY,
						DISASSEMBLE, DECOMPILE, ADAPT, ALTER, TRANSLATE, REVERSE ENGINEER OR CREATE DERIVATIVE
						WORKS OF THE SERVICES TO MAKE IT AVAILABLE TO ANY BLOCKED PERSONS OR RESTRICTED PERSONS;
						AND (2) WE DO NOT AND WILL NOT HAVE CONTROL OVER THE DEVELOPMENT, GROWTH, MAINTENANCE OR
						OPERATIONS OF ANY PROTOCOL OR SMART CONTRACT THAT YOU MAY BE ABLE TO ACCESS FROM OR
						THROUGH THE SERVICES, OR THEIR UNDERLYING SOFTWARE.
					</BodyText>
					<BodyText>
						YOU ACKNOWLEDGE, UNDERSTAND AND AGREE THAT NOTHING ON OR THROUGH THE SERVICES (INCLUDING
						REFERENCES OR LINKS TO SPECIFIC SOFTWARE, PROTOCOLS, SMART CONTRACTS AND/OR DIGITAL
						ASSETS) SHALL BE CONSIDERED OR SEEN AS PROMOTIONAL OR MARKETING CONTENT FOR SUCH
						SOFTWARE, PROTOCOLS, SMART CONTRACTS AND/OR DIGITAL ASSETS, AND WE ARE NOT ENDORSING,
						ADVERTISING PROMOTING, OR OTHERWISE MAKING KNOWN OR AVAILABLE SUCH SOFTWARE, PROTOCOLS,
						SMART CONTRACTS AND/OR DIGITAL ASSETS IN ANY SPECIFIC JURISDICTION OR INVITING OR
						INDUCING ANYONE TO ENGAGE IN ANY TYPE OF CONDUCT OR ACTIVITY IN CONNECTION WITH SUCH
						SOFTWARE, PROTOCOLS, SMART CONTRACTS AND/OR DIGITAL ASSETS.
					</BodyText>
					<BodyText>
						The Services are offered and available to users who are 18 years of age or older and who
						are not Blocked Persons or Restricted Persons. By using the Services, you represent and
						warrant that you are of legal age to form a binding contract with Company pursuant to
						applicable laws, and meet all of the foregoing eligibility requirements. If you do not
						meet all of these requirements, you must not access or use the Services.
					</BodyText>
					<StyledHeading>Trademarks</StyledHeading>
					<BodyText>
						Company’s name, the terms <strong>“DexToro”</strong> and{' '}
						<strong>“DexToro Protocol”</strong>, Company’s logos, and all related names, logos,
						product and service names, designs, and slogans are trademarks of Company or Company’s
						licensors. You must not use such marks without the prior written permission of Company
						or Company’s licensors, as applicable.
					</BodyText>
					<StyledHeading>Geographic Restrictions</StyledHeading>
					<BodyText>
						The owner of the Services is formed in the Cayman Islands. We make no claims that the
						Services or any of their contents are accessible or appropriate for Blocked Persons or
						Restricted Persons. Access to the Services by Blocked Persons or Restricted Persons may
						not be legal.
					</BodyText>

					<StyledHeading>Entire Agreement</StyledHeading>
					<BodyText>
						The Terms of Use, and any documents which we may incorporate herein by reference from
						time to time, constitute the sole and entire agreement between you and DexToro Trading
						Inc. regarding the Services and supersede all prior and contemporaneous understandings,
						agreements, representations, and warranties, both written and oral, regarding the
						Services.
					</BodyText>
				</BodyTextWrap>
			</ScrollableContent>
			<div>
				<StyledButton size="medium" onClick={onAccept}>
					Accept & Continue
				</StyledButton>
			</div>
		</StyledBaseModal>
	)
}

const StyledBaseModal = styled(BaseModal)`
	[data-reach-dialog-content] {
		max-width: 484px;
	}
	.card > div:first-child {
		justify-content: center;
	}
	.card {
		gap: 16px;
	}
	.card-header {
		height: 68px;
		margin: 0px;
	}
	.card-body {
		gap: 16px;
		padding-top: 0px;
		padding-right: 16px;
		padding-bottom: 24px;
		padding-left: 24px;
	}
`

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const DextoroDarkLogo = styled(Logo)`
	width: 200px;
	height: 28px;
	overflow: visible;
`

const DextoroLightLogo = styled(LogoLight)`
	width: 200px;
	height: 28px;
	overflow: visible;
`

const ScrollableContent = styled.div`
	overflow-y: auto;
	max-height: 500px;
	padding-right: 8px;

	::-webkit-scrollbar {
		display: inline-flex;
		width: 1px;
		height: 0;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${(props) => props.theme.colors.selectedTheme.primary};
	}

	::-webkit-scrollbar-track {
		background: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const BodyText = styled(Body)`
	font-size: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.text.primary};
`
const StyledHeading = styled.h2`
	color: ${(props) => props.theme.colors.selectedTheme.modal.terms.title};
	padding: 0.5rem 0;
	font-size: 20px;
	font-weight: 500;
	margin: 0px;
	align-self: baseline;
	padding: 0px;
`

const StyledBody = styled(Body)`
	line-height: normal;
	margin-bottom: -5px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: ${(props) => props.theme.colors.selectedTheme.modal.terms.title};
`
const BodyTextWrap = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const StyledButton = styled(Button)`
	height: 40px;
	border-radius: 10px;
	background-color: #6666ff;
	color: #ffffff;
	width: 100%;
`
