import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { FuturesMarketAsset, FuturesMarketKey, PositionSide } from '@dextoroprotocol/sdk/types'
import Wei from '@synthetixio/wei'

import { FlexDivCentered } from 'components/layout/flex'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { Container, NotificationContent } from 'constants/NotificationContainer'
import { MessageTitle } from 'sections/shared/Layout/AppLayout/Header/Notifications'

type tnsDetailsProps = {
	key: FuturesMarketKey
	asset: FuturesMarketAsset
	positionType?: PositionSide
	blockExplorerLink?: string
	status?: 'Created' | 'Open' | 'Filled' | 'Canceled'
}

function ToastContent({ message, tnsDetails }: { message: string; tnsDetails?: tnsDetailsProps }) {
	return (
		<Container
			onClick={() =>
				tnsDetails?.blockExplorerLink && window.open(tnsDetails?.blockExplorerLink, '_blank')
			}
		>
			<NotificationContent>
				<MessageTitle style={{ textTransform: 'capitalize' }}>
					<FlexDivCentered>
						<StyledCurrencyIcon currencyKey={tnsDetails?.key ?? ''} width={25} height={25} />
						{message}
					</FlexDivCentered>
					<OrderTypeBox>
						{tnsDetails?.status ?? ''}
						{tnsDetails?.status === 'Created' ? <Icon_CREATED /> : <></>}
					</OrderTypeBox>
				</MessageTitle>
			</NotificationContent>
		</Container>
	)
}

export const notifyConditionalOrder = (message: string, tnsDetails?: tnsDetailsProps) => {
	toast.info(<ToastContent message={message} tnsDetails={tnsDetails} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'notifications',
		autoClose: false,
	})
}

const StyledCurrencyIcon = styled(CurrencyIcon)`
	margin-left: -2px;
	margin-right: 4px;
`

const OrderTypeBox = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

export const Icon_CREATED = () => (
	<svg
		width="16"
		height="17"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		color="#c3c2d4"
		style={{
			width: '0.9375rem',
			height: '0.9375rem',
			minWidth: '1rem',
			minHeight: '1rem',
			marginLeft: 6,
		}}
	>
		<circle cx="8" cy="8.5" r="7.5" stroke="currentColor"></circle>
		<path
			d="M3.95947 8.5H12.0407"
			stroke="currentColor"
			stroke-width="1.25"
			stroke-linecap="round"
			stroke-dasharray="2 4"
		></path>
		<path
			d="M8 12.5404V4.45919"
			stroke="currentColor"
			stroke-width="1.25"
			stroke-linecap="round"
			stroke-dasharray="2 4"
		></path>
	</svg>
)
