import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { FC, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { WagmiConfig } from 'wagmi'
import { SpeedInsights } from '@vercel/speed-insights/next'

// import ErrorNotifier from 'components/ErrorNotifier'
import Connector from 'containers/Connector'
import { chains, wagmiClient } from 'containers/Connector/config'
import useMonitorTransactions from 'hooks/useMonitorTransactions'
import AcknowledgementModal from 'sections/app/AcknowledgementModal'
import Walkthrough from 'sections/app/Walkthrough'
import Layout from 'sections/shared/Layout'
import SystemStatus from 'sections/shared/SystemStatus'
import ManageSmartMarginAccount from 'sections/futures/ManageSmartMarginAccount'
import { WalkthroughProvider } from 'contexts/WalkthroughContext'

import { useAppData } from 'state/app/hooks'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { selectShowModal } from 'state/app/selectors'
import store from 'state/store'
import { themes } from 'styles/theme'
import logError, { IGNORE_ERRORS } from 'utils/logError'
import { getDesignTokens } from 'utils/theme'

import 'styles/main.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@reach/dialog/styles.css'
import '@rainbow-me/rainbowkit/styles.css'
import '../i18n'
import { RestrictedLocationsWarning } from 'components/Notifications'
import { set } from 'utils/localStore'

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

Sentry.init({
	dsn: 'https://888ffd9d935a12e80a8e1fdc06b9bf66@o4505773285179392.ingest.sentry.io/4505773286621184',
	maxBreadcrumbs: 50,
	debug: process.env.NODE_ENV !== 'production',
	enabled: process.env.NODE_ENV === 'production',
	release: 'dextoro@' + process.env.GIT_HASH_ID!.toString(),
	autoSessionTracking: true,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.3,
	ignoreErrors: IGNORE_ERRORS,
})

const InnerApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
	const [isReady, setReady] = useState(false)
	const { providerReady } = Connector.useContainer()
	const openModal = useAppSelector(selectShowModal)

	useAppData(providerReady)
	useMonitorTransactions()

	const getLayout = Component.getLayout || ((page) => page)
	const currentTheme = useAppSelector(selectCurrentTheme)

	const theme = useMemo(() => themes[currentTheme], [currentTheme])
	// @ts-ignore palette options
	const muiTheme = useMemo(() => createTheme(getDesignTokens(currentTheme)), [currentTheme])

	useEffect(() => {
		setReady(true)
	}, [])

	return isReady ? (
		<RainbowKitProvider
			chains={chains}
			theme={currentTheme === 'dark' ? darkTheme() : lightTheme()}
		>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={muiTheme}>
					<Layout>
						<WalkthroughProvider>
							<AcknowledgementModal />
							<Walkthrough />
						</WalkthroughProvider>
						<SystemStatus>{getLayout(<Component {...pageProps} />)}</SystemStatus>
					</Layout>
					{/* <ErrorNotifier /> */}
					{openModal === 'futures_smart_margin_manage' && <ManageSmartMarginAccount />}
				</MuiThemeProvider>
			</ThemeProvider>
		</RainbowKitProvider>
	) : null
}
const restrictedCountries = [
	'US', // United States of America
	'GB', // United Kingdom
	'CA', // Canada
	'CU', // Cuba
	'IR', // Iran
	'KP', // North Korea
	'SY', // Syria
	'MM', // Myanmar
	// 'PK', // needs to be removed added for testing
]
const restrictedRegions = [
	'Crimea', // The Crimea Region
	'Donetsk', // Donetsk Region
	'Luhansk', // Luhansk Region
]
const App: FC<AppProps> = (props) => {
	const { t } = useTranslation()
	// const dispatch = useAppDispatch()

	async function checkLocationAndShowWarning() {
		try {
			const warningShown = sessionStorage.getItem('complianceWarningShown')

			if (!warningShown) {
				const response = await fetch('https://ipinfo.io/json')
				const data = await response.json()

				if (restrictedCountries.includes(data.country) || restrictedRegions.includes(data.region)) {
					sessionStorage.setItem('complianceBannerShown', 'true')
					sessionStorage.setItem('complianceNotificationShow', 'true')
					set('unreadNotifications', true)
					RestrictedLocationsWarning(
						'Compliance Warning',
						'Perpetuals are not available to any persons who are residents of, are located or incorporated in, or have a registered agent in a blocked country or a restricted territory. More details can be found in our',
						'Terms of Use.'
					)

					sessionStorage.setItem('complianceWarningShown', 'true')
				}
			}
		} catch (error) {
			logError(error)
		}
	}

	useEffect(() => {
		checkLocationAndShowWarning()
	}, [])

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
				/>
				<meta name="title" content={t('meta.og.title')} />
				<meta name="description" content={t('meta.description')} />
				{/* open graph */}
				<meta property="og:title" content={t('meta.og.title')} />
				<meta property="og:description" content={t('meta.description')} />
				<meta property="og:url" content="https://trade.dextoro.com/" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="https://www.dextoro.com/assets/images/feature.png" />
				<meta property="og:image:alt" content={t('meta.og.title')} />
				<meta property="og:site_name" content={t('meta.og.site-name')} />
				{/* twitter */}
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:site" content="@DexToro_com" />
				<meta name="twitter:creator" content="@DexToro_com" />
				<meta
					name="twitter:image"
					content="https://dextoro-images.s3.ap-southeast-2.amazonaws.com/twitter-preview.png"
				/>
				<meta name="twitter:url" content="https://trade.dextoro.com/" />
				<meta name="twitter:image:alt" content={t('meta.og.title')} />
				<link rel="icon" href="/images/favicon.ico" />
			</Head>
			<Provider store={store}>
				<QueryClientProvider client={new QueryClient()}>
					<WagmiConfig client={wagmiClient}>
						<Connector.Provider>
							<InnerApp {...props} />
							<SpeedInsights />
						</Connector.Provider>
					</WagmiConfig>
				</QueryClientProvider>
			</Provider>
		</>
	)
}

export default App
